<template>
  <section class="profile">
    <div class="page-header">
      <h3 class="page-title">
        User
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Users</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{ fullName }}</li>
        </ol>
      </nav>
    </div>
    <div class="row">
    <div class="col-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <b-tabs v-model="activeTab" class="tab-solid tab-solid-primary">
            <b-tab>
              <template slot="title">
                <i class='mdi mdi-home-outline'></i> Summary
              </template>
              <div class="row">
                <div class="col-12">
                  <img class="img-lg rounded-circle mb-3" src="@/assets/images/faces/face1.jpg" alt="User Avatar">
                  <h3 class="card-title">{{ fullName }}</h3>
                </div>
              </div>
              <p><span class="d-block"><i class='mdi mdi-cellphone font-weight-bold'></i> {{ user.phone}}</span>
              <span class="d-block"> <i class='mdi mdi-email font-weight-bold'></i> {{ user.email}}</span>
              <span class="d-block"> <i class='mdi mdi-calendar font-weight-bold'></i>{{formatTime(user.date_created, "DD/MM/YYYY hh:mm:ss") }}</span>
              <span class="d-block"> <i class=' mdi mdi-shield font-weight-bold'></i>{{ isActive }}</span>
              </p>
            </b-tab>
            <b-tab>
              <template slot="title">
                <i class='mdi mdi-pencil'></i> Edit
              </template>
              <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
                <form @submit.prevent="handleSubmit(editUser)" class="forms-sample">
                 <div class="row">
                  <div class="col-md-6 grid-margin stretch-card">
                   <div class="card">
                    <div class="card-body">
                      <ValidationProvider name="First Name" rules="required">
                        <b-form-group slot-scope="{ errors }" label="First Name"  label-for="firstName">
                          <b-form-input type="text" id="firstName" v-model="user.first_name" placeholder="First Name"></b-form-input>
                            <p>{{ errors[0] }}</p>
                          </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Last Name" rules="required">
                        <b-form-group slot-scope="{ errors }" label="Last Name"  label-for="lastName">
                          <b-form-input type="text" id="lastName" v-model="user.last_name" placeholder="Last Name"></b-form-input>
                          <p>{{ errors[0] }}</p>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="col-12 grid-margin stretch-card">
                  <div class="card">
                    <div class="card-body">
                      <b-form-group class="float-right">
                        <b-button type="submit" variant="success" :disabled="invalid" class="mr-2">Save Changes</b-button>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            </ValidationObserver>
            </b-tab>
            <b-tab>
              <template slot="title">
                <i class='mdi mdi-account-settings'></i> Actions
              </template>
              <div class="row">
                <div class="col-12">
                  <b-button v-b-modal.delete type="button" variant="danger" class="mr-2"><i class='mdi mdi-delete'></i> Delete</b-button>
                  <b-button v-b-modal.block type="button" variant="danger" class="mr-2"><i class='mdi mdi-shield'></i> {{ blockValue }}</b-button>
                </div>
              </div>
              <b-modal id="delete" title="Delete User" size="sm" @ok="deleteUser">
                <p class="my-4">Are you sure want to delete?</p>
              </b-modal>
              <b-modal id="block" title="Block User" size="sm" @ok="blockUser">
                <p class="my-4">Are you sure want to {{ blockValue }} ?</p>
              </b-modal>
            </b-tab>
            <b-tab>
              <template slot="title">
                <i class='mdi mdi-heart'></i> Favorite Plots
              </template>
              <section class="tables">
                <div class="page-header">
                  <h3 class="page-title">
                    Favourite Plots
                  </h3>
                </div>
                <div class="row">
                  <div class="col-12 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <h4 class="card-title">Filter</h4>
                        <ValidationObserver v-slot="{}" ref="form">
                          <form @change="filterFavouritePlots" class="forms-sample">
                            <div class="row">
                              <div class="col-md-12">
                                <ValidationProvider name="date_range" rules="">
                                  <b-form-group slot-scope="{ errors }" label-for="date_range" horizontal label="Date Range">
                                    <date-picker v-model="date_range" style="width:100%;" @change="filterFavouritePlots" type="date" value-type="format" format="YYYY-MM-DD" range></date-picker>
                                    <p>{{ errors[0] }}</p>
                                  </b-form-group>
                                </ValidationProvider>
                              </div>
                            </div>
                         </form>
                        </ValidationObserver>
                      </div>
                     </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <h4 class="card-title">{{ list_title }}</h4>
                        <b-table :items="favouritePlots" id="table-list" responsive :busy="isBusyPlot" :per-page="perPage" :current-page="currentPagePlot" :fields="plot_fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                          <template v-slot:table-busy>
                            <div class="text-center text-primary my-2">
                              <b-spinner class="align-middle"></b-spinner>
                              <strong>Loading...</strong>
                            </div>
                         </template>
                         <template v-slot:cell(created_at)="data">
                           <span>{{ formatTime(data.item.created_at, "DD/MM/YYYY hh:mm:ss") }}</span>
                         </template>
                         <template v-slot:cell(updated_at)="data">
                           <span>{{ formatTime(data.item.updated_at, "DD/MM/YYYY hh:mm:ss") }}</span>
                         </template>
                       </b-table>
                       <b-pagination
                         v-model="currentPagePlot"
                         :total-rows="plotRows"
                         :per-page="perPage"
                         aria-controls="table-list"
                         align="right">
                       </b-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              <div>
            </div>
          </section>
        </b-tab>
        <b-tab>
              <template slot="title">
                <i class='mdi mdi-heart'></i> Favorite Plans
              </template>
              <section class="tables">
                <div class="page-header">
                  <h3 class="page-title">
                    Plans
                  </h3>
                </div>
                <div class="row">
                  <div class="col-12 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <h4 class="card-title">Filter</h4>
                        <ValidationObserver v-slot="{}" ref="form">
                          <form @change="filterFavouritePlans" class="forms-sample">
                            <div class="row">
                              <div class="col-md-12">
                                <ValidationProvider name="date_range" rules="">
                                  <b-form-group slot-scope="{ errors }" label-for="date_range" horizontal label="Date Range">
                                    <date-picker v-model="date_range" style="width:100%;" @change="filterFavouritePlans" type="date" value-type="format" format="YYYY-MM-DD" range></date-picker>
                                    <p>{{ errors[0] }}</p>
                                  </b-form-group>
                                </ValidationProvider>
                              </div>
                            </div>
                         </form>
                        </ValidationObserver>
                      </div>
                     </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <h4 class="card-title">Favourite Plans</h4>
                        <b-table :items="favouritePlans" id="table-list" responsive :busy="isBusyPlan" :per-page="perPage" :current-page="currentPagePlan" :fields="plan_fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                          <template v-slot:table-busy>
                            <div class="text-center text-primary my-2">
                              <b-spinner class="align-middle"></b-spinner>
                              <strong>Loading...</strong>
                            </div>
                         </template>
                         <template v-slot:cell(created_at)="data">
                           <span>{{ formatTime(data.item.created_at, "DD/MM/YYYY hh:mm:ss") }}</span>
                         </template>
                         <template v-slot:cell(updated_at)="data">
                           <span>{{ formatTime(data.item.updated_at, "DD/MM/YYYY hh:mm:ss") }}</span>
                         </template>
                       </b-table>
                       <b-pagination
                         v-model="currentPagePlan"
                         :total-rows="planRows"
                         :per-page="perPage"
                         aria-controls="table-list"
                         align="right">
                       </b-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              <div>
            </div>
          </section>
        </b-tab>
        <b-tab>
          <template slot="title">
            <i class='mdi mdi-comment'></i> Feedbacks
          </template>
          <section class="tables">
            <div class="page-header">
             <h3 class="page-title">
              Feedbacks
            </h3>
    </div>
    <div class="row">
      <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Filter</h4>
            <ValidationObserver v-slot="{}" ref="form">
              <form @change="filterFeedbacks" class="forms-sample">
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider name="date_range" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="date_range" horizontal label="Date Range">
                        <date-picker v-model="date_range" style="width:100%;" @change="filterFeedbacks" type="date" value-type="format" format="YYYY-MM-DD" range></date-picker>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="city_id" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="city_id" horizontal label="City">
                        <b-form-select id="city_id" v-model="city_id" :options="cities"/>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="plan_no">
                      <b-form-group slot-scope="{ errors }" label-for="plan_no" horizontal label="Plan No">
                        <b-form-input type="text" id="plan_no" v-model="plan_no" placeholder="Plan No"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="plot_no">
                      <b-form-group slot-scope="{ errors }" label-for="plot_no" horizontal label="Plot No">
                        <b-form-input type="text" id="plot_no" v-model="plot_no" placeholder="Plot No"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                </div>
              </form>
            </ValidationObserver>

          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Feedbacks</h4>
            <b-table :items="feedbacks" id="table-list" responsive :busy="isBusyFeedback" :per-page="perPage" :current-page="currentPageFeedback" :fields="feedback_fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template v-slot:cell(created_at)="data">
                <span>{{ formatTime(data.item.created_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:cell(updated_at)="data">
                <span>{{ formatTime(data.item.updated_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:cell(plot_id)="data">
                <span>
                  {{ data.item.plot.id }}
                </span>
              </template>
              <template v-slot:cell(plotNo)="data">
                <span>
                  {{ data.item.plot.plot }}
                </span>
              </template>
              <template v-slot:cell(planNo)="data">
                <span>
                  {{ (data.item.plan.planNo == null) ? "N/A" : data.item.plan.planNo }}
                </span>
              </template>
              <template v-slot:cell(document)="data">
                <span v-if="data.item.attachment_url != null">
                  <a :href="data.item.attachment_url" target="_blank">Attachment</a>
                </span>
                <span v-if="data.item.attachment_url == null">
                   No Attachment
                </span>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPageFeedback"
              :total-rows="feedbackRows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right">
            </b-pagination>
          </div>
        </div>
      </div>
      </div>
    <div>
    </div>
  </section>
        </b-tab>
                <b-tab>
          <template slot="title">
            <i class='mdi mdi-share'></i> Shared Plans
          </template>
            <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        Shared History
      </h3>
    </div>
    <div class="row">
      <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Filter</h4>
            <ValidationObserver v-slot="{}" ref="form">
              <form @change="filterSharedPlans" class="forms-sample">
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider name="date_range" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="date_range" horizontal label="Date Range">
                        <date-picker v-model="date_range" style="width:100%;" @change="filterSharedPlans" type="date" value-type="format" format="YYYY-MM-DD" range></date-picker>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="city_id" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="city_id" horizontal label="City">
                        <b-form-select id="city_id" v-model="city_id" :options="cities"/>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="plan_no">
                      <b-form-group slot-scope="{ errors }" label-for="plan_no" horizontal label="Plan No">
                        <b-form-input type="text" id="plan_no" v-model="plan_no" placeholder="Plan No"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                </div>
              </form>
            </ValidationObserver>

          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Shared Plans</h4>
            <b-table :items="sharedPlans" id="table-list" responsive :busy="isBusySharedPlan" :per-page="perPage" :current-page="currentPageSharedPlan" :fields="shared_plan_fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template v-slot:cell(created_at)="data">
                <span>{{ formatTime(data.item.created_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:cell(updated_at)="data">
                <span>{{ formatTime(data.item.updated_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:cell(planNo)="data">
                <span>
                  {{ (data.item.plan.planNo == null) ? "N/A" : data.item.plan.planNo }}
                </span>
              </template>
              <template v-slot:cell(district)="data">
                <span>
                  {{ data.item.plan.district }}
                </span>
              </template>
              <template v-slot:cell(viewed)="data">
                <span>
                  {{ data.item.is_viewed }}
                </span>
              </template>
              <template v-slot:cell(shared_to)="data">
                <span v-if="data.item.shared_to != null">
                  <router-link :to="{name: 'customer-view', params: {id: data.item.shared_to.id}}" target="_blank">{{`${data.item.shared_to.first_name} ${data.item.shared_to.last_name}`}}</router-link>
                </span>
                <span v-if="data.item.shared_to == null">
                  N/A
                </span>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPageSharedPlan"
              :total-rows="sharedPlanRows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right">
            </b-pagination>
          </div>
        </div>
      </div>
      </div>
    <div>
    </div>
  </section>
        </b-tab>
                        <b-tab>
          <template slot="title">
            <i class='mdi mdi-share'></i> Shared Plots
          </template>
            <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        Shared History
      </h3>
    </div>
    <div class="row">
      <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Filter</h4>
            <ValidationObserver v-slot="{}" ref="form">
              <form @change="filterSharedPlots" class="forms-sample">
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider name="date_range" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="date_range" horizontal label="Date Range">
                        <date-picker v-model="date_range" style="width:100%;" @change="filterSharedPlots" type="date" value-type="format" format="YYYY-MM-DD" range></date-picker>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="city_id" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="city_id" horizontal label="City">
                        <b-form-select id="city_id" v-model="city_id" :options="cities"/>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="plan_no">
                      <b-form-group slot-scope="{ errors }" label-for="plan_no" horizontal label="Plan No">
                        <b-form-input type="text" id="plan_no" v-model="plan_no" placeholder="Plan No"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                </div>
              </form>
            </ValidationObserver>

          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Shared Plots</h4>
            <b-table :items="sharedPlots" id="table-list" responsive :busy="isBusySharedPlot" :per-page="perPage" :current-page="currentPageSharedPlot" :fields="shared_plot_fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template v-slot:cell(created_at)="data">
                <span>{{ formatTime(data.item.created_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:cell(updated_at)="data">
                <span>{{ formatTime(data.item.updated_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:cell(plotNo)="data">
                <span>
                  {{ data.item.plot.plot }}
                </span>
              </template>
              <template v-slot:cell(plan_id)="data">
                <span>
                  {{ data.item.plot.plan_id }}
                </span>
              </template>
              <template v-slot:cell(viewed)="data">
                <span>
                  {{ data.item.is_viewed }}
                </span>
              </template>
              <template v-slot:cell(shared_with)="data">
                <span v-if="data.item.shared_with != null">
                  <router-link :to="{name: 'customer-view', params: {id: data.item.shared_with.id}}" target="_blank">{{`${data.item.shared_with.first_name} ${data.item.shared_with.last_name}`}}</router-link>
                </span>
                <span v-if="data.item.shared_with == null">
                  N/A
                </span>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPageSharedPlot"
              :total-rows="sharedPlotRows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right">
            </b-pagination>
          </div>
        </div>
      </div>
      </div>
    <div>
    </div>
  </section>
        </b-tab>
        <b-tab>
          <template slot="title">
            <i class='mdi mdi-border-all'></i> Viewed Plots
          </template>
            <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        Viewed Plots
      </h3>
    </div>
    <div class="row">
      <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Filter</h4>
            <ValidationObserver v-slot="{}" ref="form">
              <form @change="filterViewedPlots" class="forms-sample">
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider name="date_range" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="date_range" horizontal label="Date Range">
                        <date-picker v-model="date_range" style="width:100%;" @change="filterViewedPlots" type="date" value-type="format" format="YYYY-MM-DD" range></date-picker>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Plots</h4>
            <b-table :items="viewedPlots" :busy="isBusyViewedPlot" id="table-list" responsive :per-page="perPage" :current-page="currentPageViewedPlot" :fields="viewed_plot_fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template v-slot:cell(coords)="data">
                <span>{{ (data.item.plot.coords == null) ? "N/A" : data.item.plot.coords }}</span>
              </template>
              <template v-slot:cell(id)="data">
                <span>{{ (data.item.plot.id == null) ? "N/A" : data.item.plot.id }}</span>
              </template>
              <template v-slot:cell(planNo)="data">
                <span>{{ (data.item.plan.planNo == null) ? "N/A" : data.item.plan.planNo }}</span>
              </template>
              <template v-slot:cell(plot)="data">
                <span>{{ (data.item.plot.plot == null) ? "N/A" : data.item.plot.plot }}</span>
              </template>
              <template v-slot:cell(meter_sq)="data">
                <span>{{ (data.item.plot.meter_sq == null) ? "N/A" : data.item.plot.meter_sq }}</span>
              </template>
              <template v-slot:cell(neighborhood)="data">
                <span>{{ (data.item.plot.neighborhood == null) ? "N/A" : data.item.plot.neighborhood }}</span>
              </template>
              <template v-slot:cell(classification)="data">
                <span>{{ (data.item.plot.classification == null) ? "N/A" : data.item.plot.classification }}</span>
              </template>
              <template v-slot:cell(created_at)="data">
                <span>{{ formatTime(data.item.created_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:cell(action)="data">
                <b-badge pill variant="outline-info">
                  <router-link :to="{ name: 'plot-view', params: {id: data.item.plot.id} }">View</router-link>
                </b-badge>
                <b-badge pill variant="outline-info">
                  <router-link :to="{ name: 'plot-edit', params: {id: data.item.plot.id} }">Edit</router-link>
                </b-badge>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPageViewedPlot"
              :total-rows="viewedPlotRows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right">
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
    <div>
    </div>
  </section>
        </b-tab>

          </b-tabs>
          <vue-snotify></vue-snotify>
        </div>
      </div>
    </div>
    </div>
  </section>
</template>

<script>

//import StarRating from 'vue-star-rating'
import helper from '../../util/helper.js'
import Vue from "vue"
import { ValidationObserver } from 'vee-validate'
import SortedTablePlugin from "vue-sorted-table"
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import _ from 'lodash'

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})
export default {
  name: 'profile',
  beforeCreate() {
    // console.log('At this point, events and lifecycle have been initialized.')
    this.$store.dispatch("plan/emptyFavouritePlots").then(() => {}).catch(() => {})
  },
  beforeMount() {
    if (this.$route.path.indexOf('edit') >= 0) {
      this.activeTab = 1;
    }
  },
  data() {
    return {
      activeTab: 0,
      user: {},
      searchInput: "",
      sortBy: 'name',
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      date_range: "",
      start_date: "",
      end_date: "",
      city_id: "",
      plot_no: "",
      plan_no: "",
      list_title: "Plots",
      currentPagePlot: 1,
      currentPagePlan: 1,
      currentPageFeedback: 1,
      currentPageSharedPlan: 1,
      currentPageSharedPlot: 1,
      currentPageViewedPlot: 1,
      perPage: 0,
      isBusyPlot: true,
      isBusyPlan: true,
      isBusyFeedback: true,
      isBusySharedPlan: true,
      isBusySharedPlot: true,
      isBusyViewedPlot: true,
      plot_fields: [
        { key: 'id', sortable: true },
        { key: 'planNo', sortable: true },
        { key: 'plot_id', sortable: true },
        { key: 'comment', sortable: true },
        { key: 'city', sortable: true },
        { key: 'district', sortable: true },
        { key: 'created_at', sortable: true },
      ],
      plan_fields: [
        { key: 'id', sortable: true },
        { key: 'planNo', sortable: true },
        { key: 'comment', sortable: true },
        { key: 'city', sortable: true },
        { key: 'district', sortable: true},
        { key: 'created_at', sortable: true },
      ],
      feedback_fields: [
        { key: 'id', sortable: true },
        { key: 'planNo', sortable: true },
        { key: 'plot_id', sortable: true },
        { key: 'plotNo', sortable: true },
        { key: 'comment', sortable: true },
        { key: 'document', sortable: false },
        { key: 'created_at', sortable: true },
      ],
      shared_plan_fields: [
        { key: 'id', sortable: true },
        { key: 'planNo', sortable: true },
        { key: 'district', sortable: true},
        { key: 'viewed', sortable: true },
        { key: 'shared_to', sortable: true },
        { key: 'created_at', sortable: true },
      ],
      shared_plot_fields: [
        { key: 'id', sortable: true },
        { key: 'plotNo', sortable: true },
        { key: 'plan_id', sortable: true },
        { key: 'viewed', sortable: true },
        { key: 'shared_with', sortable: true },
        { key: 'created_at', sortable: true },
      ],
      viewed_plot_fields: [
        { key: 'id', sortable: true },
        { key: 'planNo', sortable: true },
        { key: 'plot', sortable: true },
        { key: 'meter_sq', sortable: true},
        { key: 'classification', sortable: true},
        { key: 'neighborhood', sortable: true},
        { key: 'coords', sortable: false},
        { key: 'created_at', sortable: false},
        { key: 'action', sortable: false}
      ],
    }
  },
  components: {
    ValidationObserver,
    DatePicker
  },
  computed: {
    isEditPage() {
      return false;
      // return this.$route.path.indexOf('edit') >= 0
    },
    fullName() {
      if(this.user.first_name) {
        return `${this.user.first_name} ${this.user.last_name}`
      }
      return " "
    },
    blockValue() {
      if (this.user.is_active != undefined)
        return (this.user.is_active == true || this.user.is_active == "true") ? "Block" : "Unblock"
      return "Block"
    },
    isActive() {
      if (this.user.is_active != undefined)
        return (this.user.is_active == true || this.user.is_active == "true") ? "Active" : "Inactive"
      return "Active"
    },
    plotRows() {
      return this.$store.state.plan.favourite_plot_meta.total
    },
    planRows() {
      return this.$store.state.plan.favourite_plan_meta.total
    },
    feedbackRows() {
      return this.$store.state.plot.feedback_meta.total
    },
    sharedPlanRows() {
      return this.$store.state.plan.shared_meta.total
    },
    sharedPlotRows() {
      return this.$store.state.plot.shared_meta.total
    },
    viewedPlotRows() {
      return this.$store.state.plot.viewed_meta.total
    },
    paginationPageSize() {
      return this.$store.state.plan.favourite_plot_meta.paginate
    },
    favouritePlots() {
      return this.$store.state.plan.favourite_plots
    },
    favouritePlans() {
      return this.$store.state.plan.favourite_plans
    },
    sharedPlans() {
      return this.$store.state.plan.shared
    },
    sharedPlots() {
      return this.$store.state.plot.shared
    },
    viewedPlots() {
      return this.$store.state.plot.viewed
    },
    feedbacks() {
      return this.$store.state.plot.feedbacks
    },
    cities() {
      let cities = this.$store.getters['city/listCities']
      cities.unshift({value: "", text: "Select City"})
      return cities
    }
  },
  watch: {
    currentPagePlot() {
      if(this.currentPagePlot != this.$store.state.plan.favourite_plot_meta.current_page)
        this.fetchFavouritePlots(this.currentPagePlot)
    },
    currentPageFeedback() {
      if(this.currentPageFeedback != this.$store.state.plot.feedback_meta.current_page)
        this.fetchFeebacks(this.currentPage)
    },
    date_range(newVal) {
      if(newVal[0] != null && newVal[1] != null) {
        this.start_date = newVal[0]
        this.end_date = newVal[1]
      }
      else {
        this.start_date = ""
        this.end_date = ""
      }
    }
  },
  methods: {
    formatTime(time, format) {
      return helper.formatTime(time, format)
    },
    editUser() {
      this.$store.dispatch(
        "customer/updateCustomer", 
        {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          id: this.$route.params.id
        }
      ).then((res) => {
        this.user = res.data.data
        this.$snotify.success(res.data.message, {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true
        })
      })
      .catch(() => {
        this.$snotify.warning('Something went wrong!', {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true
        })
      })
    },
    deleteUser() {
      this.$store.dispatch("customer/deleteCustomer", this.$route.params.id)
      .then(() => {
        this.$router.push("/customers/list")
      })
      .catch(() => {
        this.$snotify.warning('Something went wrong!', {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true
        })
      })
    },
    blockUser() {
      let blockValue = "false"
      if (this.blockValue == "Unblock")
        blockValue = "true"
      this.$store.dispatch("customer/updateCustomer", {is_active: blockValue, id: this.$route.params.id})
      .then((res) => {
        this.user = res.data.data
        this.$snotify.success(res.data.message, {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true
        })
      })
      .catch(() => {
        this.$snotify.warning('Something went wrong!', {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true
        })
      })
    },
    filterFavouritePlots: _.debounce(function() {
      this.fetchFavouritePlots(this.currentPagePlot)
    }, 2000),
    filterFavouritePlans: _.debounce(function() {
      this.fetchFavouritePlans(this.currentPagePlan)
    }, 2000),
    filterFeedbacks: _.debounce(function() {
      this.fetchFeedbacks(this.currentPageFeedback)
    }, 2000),
    filterSharedPlans: _.debounce(function() {
      this.fetchSharedPlans(this.currentPageSharedPlan)
    }, 2000),
    filterSharedPlots: _.debounce(function() {
      this.fetchSharedPlots(this.currentPageSharedPlot)
    }, 2000),
    filterViewedPlots: _.debounce(function() {
      this.fetchViewedPlots(this.currentPageViewedPlot)
    }, 2000),
    fetchFavouritePlots(current_page = 1) {
      this.isBusyPlot = true
      let payload = {
        meta: {
          page: current_page,
          perPage: this.paginationPageSize,
          start_date: this.start_date,
          end_date: this.end_date,
          user_id: this.$route.params.id
        },
      }

      if (payload.meta.start_date == "") delete payload.meta.start_date
      if (payload.meta.end_date == "") delete payload.meta.end_date

      this.$store.dispatch("plan/fetchPlotFavourites", payload)
      .then(() => {
        this.isBusyPlot = false
      })
      .catch(() => {
        this.isBusyPlot = false
      })
    },
    fetchFavouritePlans(current_page = 1) {
      this.isBusyPlan = true
      let payload = {
        meta: {
          page: current_page,
          perPage: this.paginationPageSize,
          start_date: this.start_date,
          end_date: this.end_date,
          user_id: this.$route.params.id
        },
      }

      if (payload.meta.start_date == "") delete payload.meta.start_date
      if (payload.meta.end_date == "") delete payload.meta.end_date

      this.$store.dispatch("plan/fetchPlanFavourites", payload)
      .then(() => {
        this.isBusyPlan = false
      })
      .catch(() => {
        this.isBusyPlan = false
      })
    },
    fetchCities() {
      this.isBusyFeedback = true

      const payload = {
        meta: {
          page: 1,
          paginate: 200
        },
      }
      this.$store.dispatch("city/fetchCities", payload)
      .then(() => {
        this.isBusyFeedback = false
      })
    },
    fetchFeedbacks(current_page = 1) {
      this.isBusyFeedback = true
      let payload = {
        meta: {
          page: current_page,
          perPage: this.paginationPageSize,
          start_date: this.start_date,
          end_date: this.end_date,
          plan_no: this.plan_no,
          plot_no: this.plot_no,
          city_id: this.city_id,
          user_id: this.$route.params.id
        },
      }

      for (const [key, value] of Object.entries(payload.meta)) {
        if(value == "")
          delete payload.meta[key]
      }

      this.$store.dispatch("plot/fetchFeedbacks", payload)
      .then(() => {
        this.isBusyFeedback = false
      })
      .catch(() => {
        this.isBusyFeedback = false
      })
    },
    fetchSharedPlans(current_page = 1) {
      this.isBusySharedPlan = true
      let payload = {
        meta: {
          page: current_page,
          perPage: this.paginationPageSize,
          start_date: this.start_date,
          end_date: this.end_date,
          plan_no: this.plan_no,
          plot_no: this.plot_no,
          city_id: this.city_id,
          user_id: this.$route.params.id
        },
      }

      for (const [key, value] of Object.entries(payload.meta)) {
        if(value == "")
          delete payload.meta[key]
      }

      this.$store.dispatch("plan/sharedHistory", payload)
      .then(() => {
        this.isBusySharedPlan = false
      })
      .catch(() => {
        this.isBusySharedPlan = false
      })
    },
    fetchSharedPlots(current_page = 1) {
      this.isBusySharedPlot = true
      let payload = {
        meta: {
          page: current_page,
          perPage: this.paginationPageSize,
          start_date: this.start_date,
          end_date: this.end_date,
          plan_no: this.plan_no,
          plot_no: this.plot_no,
          city_id: this.city_id,
          user_id: this.$route.params.id
        },
      }

      for (const [key, value] of Object.entries(payload.meta)) {
        if(value == "")
          delete payload.meta[key]
      }

      this.$store.dispatch("plot/sharedHistory", payload)
      .then(() => {
        this.isBusySharedPlot = false
      })
      .catch(() => {
        this.isBusySharedPlot = false
      })
    },
    fetchViewedPlots(current_page = 1) {
      this.isBusyViewedPlot = true
      let payload = {
        meta: {
          page: current_page,
          perPage: this.paginationPageSize,
          start_date: this.start_date,
          end_date: this.end_date,
          user_id: this.$route.params.id
        },
      }

      for (const [key, value] of Object.entries(payload.meta)) {
        if(value == "")
          delete payload.meta[key]
      }

      this.$store.dispatch("plot/viewedPlots", payload)
      .then(() => {
        this.isBusyViewedPlot = false
      })
      .catch(() => {
        this.isBusyViewedPlot = false
      })
    },
  },
  created() {
    this.$store.dispatch("customer/fetchCustomer", this.$route.params.id)
    .then(res => {
       this.user = res.data.data
    })
    .catch(() => {
      this.$router.push("/customers/list")
    })

    this.fetchFavouritePlots()
    this.fetchFavouritePlans()
    this.fetchCities()
    this.fetchFeedbacks()
    this.fetchSharedPlans()
    this.fetchSharedPlots()
    this.fetchViewedPlots()
  }
}
</script>
